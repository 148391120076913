<template>
    <div class="movie" v-if="data ">

            <div class="head" > <img  @click="$goBack()" src="../../assets/img/back_white.png" alt=""> <div class="title">订单详情</div></div>

            <div class="main">
                
                <div class="box">

                        <div class="shop">

                            <img :src="data.pics" alt=""  v-if="$route.query.type==='1'">
                            <img :src="data.listOrderItem[0].picUrl" alt=""  v-if="$route.query.type==='0'">

                        
                            <div class="info">

                                <div class="name"  v-if="$route.query.type==='1'">{{data.movieName}}</div>
                                <div class="name"  v-if="$route.query.type==='0'">{{data.name}}</div>
                                 

                                <div class="middle">

                                    <div class="lables">

                                        <div  >
                                                {{data.planType ||data.shopInfo.detail}}
                                                 <!-- <p></p> -->
                                        </div>
                                        <p v-if="data.hallName || data.seatNos" style="width:1px; height:0.25rem;background:#999;margin:0 0.2rem"></p>
                                         <div   >
                                                {{data.hallName }}
                                                 <!-- <p></p> -->
                                        </div>
                                         <div class="br">{{data.seatNos}}</div>
                                       
                                    </div>
                                     <div  v-if="$route.query.type==='1'">
                                          <span style="margin-right:0.26rem">放映时间:</span>  {{data.showTime}}
                                        </div>
                                    <div class="time">
                                        <!-- 营业时间 {{(data.shopInfo.openingTime).replace(':00','') +'~'+ (data.shopInfo.closingTime).replace(':00','')}}  -->
                                    </div>
                                        
                                   
                                </div>
                                
                                 <div class="address" v-if="data.shopInfo" >{{data.address||data.shopInfo.address}} </div>
                                <div class="address" v-if="data.address" >{{data.address}} </div>

                            </div>
                            
                           

                        </div>
                        
                        <div class="warp">
                                    <div class="p1 p"></div>
                                    <div class="p2 p"></div>
                                    <div class="p3 p"></div>
                                    <div class="p4 p"></div>
                                <div class="title">

                                    票券核验

                                </div>

                                <div class="scaner">

                                    <!-- <qr  text="https://www.baidu.com" :size="200" ></qr> -->


                                    <div class="ticket" >

                                        {{data.ticketCount ||data.listOrderItem[0].quantity}}张票券

                                    </div>

                                </div>

                                <div class="code" v-if="$route.query.type==='1' && data.isPay == 1">
                                    
                                    兑换码：{{data.ticketCode.slice(2,-2).replace(/(\w{4})(?=\w)/g, '$1 ') ||data.checkCode}}

                                </div>   
                                <div class="code" v-if="$route.query.type==='0' && data.isPay == 1">
                                    
                                   <span v-if="data.status!=4 && data.orderRefundStatu != 12 "> 兑换码：{{data.checkCode}}</span>
                                    <div  v-if="data.status == 4" style="text-align:center;font-weight: 600;"> 已核销</div>
                                    <div  v-if="data.orderRefundStatu == 12" style="text-align:center;font-weight: 600;"> 已退款</div>
                                </div>  
                        </div>

                     
                        <div class="location" style="border-bottom: 1px dotted #999;">


                            <div class="left">

                                <div class="name" >{{data.cinemaName ||data.shopInfo.name}}</div>
                                <!-- <div class="name" v-if="$route.query.type==='1'">{{data.cinemaName ||data.shopInfo.name}}</div> -->


                                <div class="address" v-if="data.shopInfo" >{{data.address||data.shopInfo.address}} </div>
                                <div class="address" v-if="data.address" >{{data.address}} </div>
                                    
                            </div>
                            
                             <a  v-if="$route.query.type==='0'" :href="'tel:' + data.shopInfo.phone"> <img   src="../../assets/img/phone.png" alt=""></a> 
                        </div>
                        <div class="location">

                            <div class="left">

                                <div class="name" >实付金额：¥{{data.paymentPrice ||data.listOrderItem[0].paymentPrice}}</div>
                                <div class="address copy"   :data-clipboard-text="data.orderNo" @click="copy()" >订单号：{{data.orderNo}} </div>
                                <div class="address">购买时间：{{data.createTime ||data.listOrderItem[0].createTime}} </div>
                                <div class="address" v-if="$route.query.type==='1'">手机号：{{data.phone}} </div>

                               
                                <!-- <div class="address" v-if="$route.query.type==='0'">手机号：{{data.phone}} </div> -->


                            </div>
                           
                        </div>

                        <div class="footer" v-if="data.type=='5'" @click="$contact(data.imAccount)">

                            <div>联系客服</div>

                            <img src="../../assets/img/right_gery.png" alt="">

                        </div>
                </div>

            </div>
    </div>
</template>

<script>
import {movieDetail} from '../../api/orderDetail/index'
import {getOrderDetail} from '../../api/orderDetail/index'


// import qr from 'vue-qr'
    export default {
        // components:{qr},
        data(){ 
            return{ 
                data:null
            }
        },
        created(){ 

            // if(this.$route.query.session){ 
            //     this.$store.commit('setSession', '4701e68e-f098-4d88-a20a-d54acd9f5fa8')
            // }
            this.getDetail()
        },
        mounted(){
            this.setRem()
        },
        methods:{ 
            getDetail(){ 
                console.log(this.$route.query)
                if(this.$route.query.type==='1'){ 
                    movieDetail(this.$route.query.id).then(res=>{
                        if(res.data.code===0){ 
                            //
                            this.data=res.data.data
                            try{ 
                                window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({color:'#00AFFF'}))
                            }catch{ 
                                //
                            }
                                

                        

                        }
                    })
                }else{ 
                    getOrderDetail(this.$route.query.id).then(res=>{ 
                        if(res.data.code===0){ 
                            //
                            this.data=res.data.data
                            try{ 
                                //
                                 window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({color:'#00AFFF'}))
                            }catch{ 
                                //
                            }
                       

   }
                        
                    })
                }
            },
            copy(){ 

                
                 let clipboard = new this.Clipboard('.copy');
                    clipboard.on("success", () => {
                        this.$toast("复制成功");
                        // 释放内存
                        clipboard.destroy();
                    });
                    clipboard.on("error", () => {
                        // 不支持复制
                        this.$toast("该浏览器不支持自动复制");
                        // 释放内存
                        clipboard.destroy();
                    });
                
            
        },
        },
         beforeDestroy(){ 
      window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))

  }

    }
</script>

<style lang="less" scoped>
    .movie{
        background-color: #00AFFF;
        position: fixed;
        left: 0;
        width: 0;
        height: 100vh;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-family: PingFangSC-Regular, PingFang SC;
        .head{
            display: flex;
            align-items: center;
            padding: 0.26rem .133333rem;
            color: #F1F1F1;
            img{
                width: .64rem;
                height: .666667rem;
              
            }
            .title{
                flex: 1;
                margin-right: .64rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .453333rem;
            }
        }
        .main{
            flex: 1;
            box-sizing: border-box;
            overflow: auto;
            padding: 0 .426667rem;
            .box{
                width: 100%;
               
                background-color: white;
                border-radius: .266667rem;
                margin-bottom: 2rem;
                margin-top: 1rem;
                overflow: hidden;
                box-sizing: border-box;
                padding: .4rem;
                .shop{
                    display: flex;
                    font-size: .32rem;
                    color: #999999;
                    border-bottom: 1px dotted #999;
                    padding-bottom: 20px;
                    img{
                        height: 1.866667rem;
                        width: 1.866667rem;
                        border-radius: .133333rem;
                        margin-right: .266667rem;
                        box-sizing: border-box;
                    }
                    .info{
                        display: flex;
                        flex-direction: column;
                        .name{
                            color: #333333;
                            font-size: .373333rem;
                            margin-bottom: .106667rem;
                        }
                        .middle{
                            flex: 1;
                          
                            margin-bottom: .106667rem;
                            .lables{
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                               
                                div{
                                   
                                    display: flex;
                                    align-items: center;
                                    p{
                                        display: inline-block;
                                        height: .32rem;
                                        width: 1px;
                                        background-color: #999;
                                      
                                    }
                                }

                            }
                            
                        }
                    }
                   
                }
                .warp{
                    position: relative;
                    font-size: .373333rem;
                    color: #333;
                    padding: .24rem 0 .8rem;
                    border-bottom: 1px dotted #999;
                    .title{
                        margin-bottom: .6rem;
                    }
                    .scaner{
                        margin: auto;
                        text-align: center;
                      
                       margin-bottom: .426667rem;
                    }
                    .code{
                            padding:  .4rem 1.253333rem;
                            border: 2px solid #333333;
                    }
                    .p{
                        border-radius: 50%;
                        position:absolute ;
                        background-color:#00AFFF ;
                        width: .266667rem;
                        height:.266667rem;
                    }
                    .p1{
                       left:-0.56rem;
                       top: -0.133333rem;
                    }
                    .p2{
                         top: -0.133333rem;
                         right:-0.56rem;
                    }
                    .p3{
                        bottom: -0.133333rem;
                         right:-0.56rem;
                    }
                     .p4{
                        bottom: -0.133333rem;
                         left:-0.56rem;
                    }
                }
                .location{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                      padding: .506667rem 0 .64rem;
                    .left{
                       
                        box-sizing: border-box;
                        
                        .name{
                            color: #333333;
                            font-size: .373333rem;
                            margin-bottom: .506667rem;
                           
                        }
                        .address{
                            font-size: .32rem;
                            color: #999999;
                            margin-bottom: 0.26rem;
                        }
                    }
                    img{
                        margin: 0 .666667rem;
                        height: .64rem;
                        width: .64rem;
                    }
                }
            }
            .footer{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: .506667rem 0;
                color: #333333;
                font-size: .373333rem;
                border-top: 1px dotted #999;
                
                img{
                    width: .313333rem;
                    height: .446667rem;
                }
            }
        }
    }
    .br{ 
        white-space:none;
    }
</style>